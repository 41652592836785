export const LOAD = "[APP] Load";
export const LOAD_SUCCESS_AUTHENTICATED = "[APP] Load Success Authenticated";
export const LOAD_SUCCESS_UNAUTHENTICATED = "[APP] Load Success Unauthenticated";
export const LOAD_FAIL = "[APP] Load Fail";

export const LOAD_TRANSLATIONS = "[APP] Load Translations";
export const LOAD_TRANSLATIONS_SUCCESS = "[APP] Load Translations Success";
export const LOAD_TRANSLATIONS_FAIL = "[APP] Load Translations Fail";

export const LOAD_CONSTANTS = "[APP] Load Constants";
export const LOAD_CONSTANTS_SUCCESS = "[APP] Load Constants Success";
export const LOAD_CONSTANTS_FAIL = "[APP] Load Constants Fail";

export const NOTIFICATION_SHOW = "[APP] Notification Show";
export const NOTIFICATION_HIDE = "[APP] Notification Hide";

export const LOADING_SHOW = "[APP] Show Loading";
export const LOADING_HIDE = "[APP] Hide Loading";

export const CLEAR_CACHE = "[APP] Clear Cache";

export const CLEAR_TRANSLATIONS = "[APP] Clear Translations";

export const LOAD_NOTES = "[APP] Load Notes";
export const SAVE_NOTES = "[APP] Save Notes";

export const TOGGLE_STATISTICS_PRIVACY = "[APP] Toggle Statistics Values";
export const INIT_STATISTICS_PRIVACY = "[APP] Init Statistics Values";

export const TO_DEMO = "[APP] To Demo";
export const TO_LIVE = "[APP] To Live";
export const LOAD_MODE = "[APP] Load Mode";
